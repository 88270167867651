import React from 'react';
import { PersonalityProvider } from './contexts/PersonalityContext';
import GameContainer from './components/GameContainer';

const App = () => {
  return (
    <PersonalityProvider>
      <GameContainer />
    </PersonalityProvider>
  );
};

export default App;