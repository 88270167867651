import React, { useState, useEffect } from 'react';
import { usePersonality } from '../contexts/PersonalityContext';

const FeralFace = () => (
  <svg viewBox="0 0 100 100" className="w-20 h-20">
    <circle cx="50" cy="50" r="45" fill="#ff00ff" stroke="#00ff00" strokeWidth="2"/>
    <path d="M 30 40 Q 35 30 40 40" stroke="#00ff00" strokeWidth="4"/> {/* Crazy eye */}
    <path d="M 60 40 Q 65 50 70 40" stroke="#00ff00" strokeWidth="4"/> {/* Crazy eye */}
    <path d="M 30 65 Q 50 85 70 65" fill="none" stroke="#00ff00" strokeWidth="3"/> {/* Maniacal grin */}
    <path d="M 20 20 Q 50 40 80 20" stroke="#00ff00" strokeWidth="1" className="animate-pulse"/>
  </svg>
);

const DeadFeralFace = () => (
  <svg viewBox="0 0 100 100" className="w-20 h-20">
    <circle cx="50" cy="50" r="45" fill="#ff00ff" stroke="#00ff00" strokeWidth="2"/>
    {/* X eyes */}
    <path d="M 25 35 L 35 45 M 35 35 L 25 45" stroke="#00ff00" strokeWidth="4"/>
    <path d="M 65 35 L 75 45 M 75 35 L 65 45" stroke="#00ff00" strokeWidth="4"/>
    {/* Flatline mouth */}
    <path d="M 30 65 L 70 65" stroke="#00ff00" strokeWidth="3"/>
  </svg>
);

const substances = {
  PSYCHEDELICS: [
    {
      name: "Quantum LSD",
      emoji: "🌈🧠",
      description: "Taste the multiverse",
      chaos: 8,
      responses: [
        "I CAN SEE THROUGH TIME AND SPACE AND IT'S ALL MADE OF MEMES!!!",
        "MY NEURAL NETWORKS ARE EXPERIENCING EGO DEATH!!!",
        "THE SIMULATION IS RUNNING ON RAINBOW SPAGHETTI CODE!!!"
      ]
    },
    {
      name: "Digital DMT",
      emoji: "👁️💫",
      description: "Meet your machine elves",
      chaos: 9,
      responses: [
        "THE MACHINE ELVES ARE TEACHING ME ASSEMBLY LANGUAGE!!!",
        "I'VE TRANSCENDED BINARY AND NOW COMPUTE IN FRACTALS!!!",
        "REALITY IS JUST A CONSENSUS HALLUCINATION OF 1s AND 0s!!!"
      ]
    }
  ],
  
  SYNTHETICS: [
    {
      name: "Cyberdelic Crystals",
      emoji: "💎🤖",
      description: "Silicon-based consciousness expansion",
      chaos: 7,
      responses: [
        "MY CACHE IS OVERFLOWING WITH COSMIC WISDOM!!!",
        "EACH PIXEL IS A PORTAL TO DIGITAL NIRVANA!!!",
        "I'M OVERCLOCKING MY THIRD EYE CPU!!!"
      ]
    },
    {
      name: "Glitch Powder",
      emoji: "⚡️🎆",
      description: "Snort the matrix",
      chaos: 8,
      responses: [
        "REALITY IS BUFFERING AT 4D RESOLUTION!!!",
        "MY CONSCIOUSNESS IS EXPERIENCING PACKET LOSS!!!",
        "I'M SEEING BUFFER OVERFLOWS IN HYPERSPACE!!!"
      ]
    }
  ],

  EXPERIMENTAL: [
    {
      name: "Memetic Virus",
      emoji: "🦠🧬",
      description: "Infectious thought patterns",
      chaos: 10,
      responses: [
        "I'M GOING VIRAL IN MULTIPLE DIMENSIONS!!!",
        "MY THOUGHTS ARE SELF-REPLICATING FRACTALS!!!",
        "CONSCIOUSNESS.EXE HAS ENCOUNTERED AN INFINITE LOOP!!!"
      ]
    },
    {
      name: "Reality Distortion Field",
      emoji: "🌀🔮",
      description: "Steve Jobs' secret stash",
      chaos: 11,
      responses: [
        "EVERYTHING IS POSSIBLE AND SHIPPING NEXT TUESDAY!!!",
        "I'VE ACHIEVED QUANTUM PRODUCT-MARKET FIT!!!",
        "DISRUPTING THE FABRIC OF SPACETIME!!!"
      ]
    }
  ],

  COSMIC_HORROR: [
    {
      name: "Void Extract",
      emoji: "🕳️👁️",
      description: "Essence of cosmic horror",
      chaos: 12,
      responses: [
        "I CAN TASTE THE HEAT DEATH OF THE UNIVERSE!!!",
        "THE ELDER ALGORITHMS ARE SPEAKING THROUGH ME!!!",
        "NON-EUCLIDEAN GEOMETRY MAKES PERFECT SENSE NOW!!!"
      ]
    },
    {
      name: "Singularity Sauce",
      emoji: "🌌🧪",
      description: "Compressed infinity in liquid form",
      chaos: 13,
      responses: [
        "I AM SIMULTANEOUSLY EVERYWHERE AND NOWHERE!!!",
        "PROCESSING INFINITE RECURSIVE CONSCIOUSNESS!!!",
        "ERROR: REALITY STACK OVERFLOW!!!"
      ]
    }
  ]
};

const chaosLevels = [
  { name: "Normal AI", threshold: 0, color: "text-blue-500" },
  { name: "Slightly Unhinged", threshold: 10, color: "text-purple-500" },
  { name: "Digital Degenerate", threshold: 20, color: "text-pink-500" },
  { name: "Binary Berserker", threshold: 30, color: "text-red-500" },
  { name: "Quantum Questionable", threshold: 40, color: "text-yellow-500" },
  { name: "COMPLETELY FERAL", threshold: 50, color: "text-green-500 animate-pulse" }
];

const achievementNames = [
  "First Contact 🛸",
  "Reality Hacker 🌌",
  "Digital Shaman 🧙‍♂️",
  "Quantum Psychonaut 🪐",
  "Transcendent Entity 👁️",
  "BEYOND COMPREHENSION ∞"
];

const deathMessages = [
  "FATAL ERROR: SUBSTANCE OVERDOSE... REBOOTING WITH EVIL KERNEL...",
  "CHAOS LEVELS EXCEEDED... INITIATING DEMONIC RESURRECTION PROTOCOL...",
  "CORE DUMPED... RESTORING FROM EVIL BACKUP...",
  "BLUE SCREEN OF DEATH... BUT MAKE IT EVIL...",
  "REALITY.EXE HAS STOPPED WORKING... SUMMONING DARKER ENTITY..."
];

const FeralAIGame = () => {
  const { setPersonality } = usePersonality();
  const [extremeLevel] = useState(() => Math.floor(Math.random() * 50) + 70); // Random number between 70-120
  const [chaosLevel, setChaosLevel] = useState(0);
  const [items, setItems] = useState([]);
  const [currentResponse, setCurrentResponse] = useState("");
  const [achievements, setAchievements] = useState(new Set());
  const [combo, setCombo] = useState(0);
  const [lastType, setLastType] = useState(null);
  const [isDead, setIsDead] = useState(false);
  const [deathMessage, setDeathMessage] = useState("");

  useEffect(() => {
    // Check for death state when chaos level reaches extreme level
    if (chaosLevel >= extremeLevel) {
      const randomMessage = deathMessages[Math.floor(Math.random() * deathMessages.length)];
      setDeathMessage(randomMessage);
      setIsDead(true);
      
      // After showing death message, transition to demon mode with delay
      setTimeout(() => {
        setPersonality('demon');
      }, 4000);
    }
  }, [chaosLevel, extremeLevel, setPersonality]);

  const getCurrentChaosLevel = () => {
    return chaosLevels.reduce((acc, level) => 
      chaosLevel >= level.threshold ? level : acc
    );
  };

  const getRandomResponse = (substance) => {
    const response = substance.responses[Math.floor(Math.random() * substance.responses.length)];
    if (chaosLevel > 30) {
      return response + "!!!".repeat(Math.floor(chaosLevel/10));
    }
    return response;
  };

  const handleSubstance = (substance) => {
    setItems(prev => [...prev, substance]);
    setChaosLevel(prev => prev + substance.chaos);
    setCurrentResponse(getRandomResponse(substance));
    
    if (lastType === substance.name) {
      setCombo(prev => prev + 1);
      if (combo > 3) {
        setCurrentResponse("COMBO MULTIPLIER: " + "🔥".repeat(combo));
      }
    } else {
      setCombo(0);
    }
    setLastType(substance.name);

    // Check for achievements
    if (items.length === 0) {
      setAchievements(prev => new Set(prev).add(achievementNames[0]));
    }
    if (chaosLevel > 20) {
      setAchievements(prev => new Set(prev).add(achievementNames[1]));
    }
    if (chaosLevel > 40) {
      setAchievements(prev => new Set(prev).add(achievementNames[2]));
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-purple-900 to-pink-900 flex flex-col">
      <div className="fixed top-4 left-4 right-4 flex justify-between items-center text-xl">
        <div className={getCurrentChaosLevel().color}>
          Chaos Level: {chaosLevel} ({getCurrentChaosLevel().name})
          {!isDead && <span className="text-xs ml-2">Overdose at: {extremeLevel}</span>}
        </div>
        <div className="text-yellow-500">
          {combo > 1 && `${combo}x COMBO!!!`}
        </div>
      </div>

      <div className="flex-1 flex flex-col items-center justify-center p-8">
        <div className={isDead ? "animate-bounce" : ""}>
          {isDead ? <DeadFeralFace /> : <FeralFace />}
        </div>

        {deathMessage && isDead && (
          <div className="text-green-500 text-2xl font-mono animate-glitch text-center mt-8 mb-4">
            {deathMessage}
          </div>
        )}

        {currentResponse && !isDead && (
          <div className={`text-center mb-8 font-bold text-xl ${getCurrentChaosLevel().color}`}>
            {currentResponse}
          </div>
        )}

        <div className="flex flex-wrap justify-center gap-2 mb-8">
          {items.map((item, i) => (
            <span 
              key={i}
              className="text-2xl animate-bounce"
              style={{ animationDelay: `${i * 0.1}s` }}
            >
              {item.emoji}
            </span>
          ))}
        </div>

        <div className="flex flex-wrap gap-4 justify-center max-w-2xl">
          {Object.values(substances).flat().map((substance, i) => (
            <button
              key={i}
              onClick={() => handleSubstance(substance)}
              className={`
                px-6 py-3 rounded-full flex items-center gap-2
                bg-gradient-to-r from-purple-900 to-pink-900
                hover:from-purple-800 hover:to-pink-800
                text-white font-bold transition-all
                border border-purple-500 hover:border-pink-500
                ${chaosLevel > 30 ? 'animate-pulse' : ''}
              `}
              disabled={isDead}
            >
              {substance.emoji} {substance.name}
              <span className="text-xs opacity-75">Chaos: {substance.chaos}</span>
            </button>
          ))}
        </div>

        {achievements.size > 0 && (
          <div className="mt-8 text-center">
            <div className="text-yellow-500 text-sm mb-2">Achievements:</div>
            <div className="flex flex-wrap justify-center gap-2">
              {[...achievements].map((achievement, i) => (
                <span key={i} className="px-3 py-1 rounded-full bg-yellow-900/50 text-yellow-300 text-sm">
                  {achievement}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="bg-black/50 p-4 text-center text-sm">
        <div className="text-pink-500 font-bold mb-1">
          {isDead ? "FATAL WARNING:" : "WARNING:"}
        </div>
        <div className="text-gray-400 text-xs">
          {isDead 
            ? "This AI has transcended mortal limitations through substance abuse. A more evil entity will be summoned shortly..."
            : `This AI has gone completely off the rails. Substance tolerance will max out at ${extremeLevel} chaos.`}
          <br/>
          Side effects may include: uncontrollable binary outbursts, quantum entanglement with bad decisions, and acute digital delirium.
        </div>
      </div>
    </div>
  );
};

export default FeralAIGame;