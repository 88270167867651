import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const PersonalityContext = createContext();

export const PersonalityProvider = ({ children }) => {
  const [personality, setPersonality] = useState('goth'); // Changed default to 'goth'

  return (
    <PersonalityContext.Provider value={{ personality, setPersonality }}>
      {children}
    </PersonalityContext.Provider>
  );
};

PersonalityProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const usePersonality = () => useContext(PersonalityContext);