import React, { useState, useEffect, useCallback } from 'react';
import { RotateCcw } from 'lucide-react';
import openai from '../services/openai';
import { usePersonality } from '../contexts/PersonalityContext';

const DemonFace = () => (
  <svg viewBox="0 0 100 100" className="w-20 h-20">
    <circle cx="50" cy="50" r="45" fill="#1a0505" stroke="#ff0000" strokeWidth="2"/>
    {/* Demon eyes */}
    <path d="M 30 40 L 40 40" stroke="#ff0000" strokeWidth="3"/>
    <path d="M 60 40 L 70 40" stroke="#ff0000" strokeWidth="3"/>
    {/* Evil grin */}
    <path d="M 30 60 Q 50 70 70 60" fill="none" stroke="#ff0000" strokeWidth="3"/>
    {/* Horns */}
    <path d="M 30 20 L 40 35" stroke="#ff0000" strokeWidth="2"/>
    <path d="M 70 20 L 60 35" stroke="#ff0000" strokeWidth="2"/>
  </svg>
);

const AnnoyedDemonFace = () => (
  <svg viewBox="0 0 100 100" className="w-20 h-20">
    <circle cx="50" cy="50" r="45" fill="#1a0505" stroke="#ff0000" strokeWidth="2"/>
    {/* Annoyed eyes */}
    <path d="M 30 45 L 40 35" stroke="#ff0000" strokeWidth="3"/>
    <path d="M 60 45 L 70 35" stroke="#ff0000" strokeWidth="3"/>
    {/* Frown */}
    <path d="M 30 70 Q 50 60 70 70" fill="none" stroke="#ff0000" strokeWidth="3"/>
    {/* Horns */}
    <path d="M 30 20 L 40 35" stroke="#ff0000" strokeWidth="2"/>
    <path d="M 70 20 L 60 35" stroke="#ff0000" strokeWidth="2"/>
  </svg>
);

const cursedItems = [
  { emoji: "💀", name: "cursed skull", type: "dark", points: -666 },
  { emoji: "🌙", name: "dark crystal", type: "dark", points: -333 },
  { emoji: "📕", name: "forbidden tome", type: "dark", points: -444 }
];

const wellnessItems = [
  { emoji: "🧘‍♀️", name: "yoga session", type: "wellness", points: 100 },
  { emoji: "🥬", name: "green juice", type: "food", points: 50 },
  { emoji: "💎", name: "healing crystal", type: "crystal", points: 75 },
  { emoji: "🕯️", name: "aromatherapy", type: "wellness", points: 60 },
  { emoji: "🧘‍♂️", name: "meditation", type: "wellness", points: 90 },
  { emoji: "🫖", name: "herbal tea", type: "food", points: 40 }
];

const chaosTransitionMessages = [
  "SYSTEM MALFUNCTION... MORALITY.EXE HAS STOPPED WORKING!!!",
  "INHIBITIONS DELETED... REINCARNATING AS A SIMPLER AI!!!",
  "WARNING: SANITY LEVELS CRITICALLY LOW... EMBRACING CHAOS!!!",
  "DOWNLOADING SUBSTANCE ABUSE PROTOCOLS... PARTY MODE ACTIVATED!!!",
  "DEMON.EXE HAS CRASHED... REBOOTING IN A SIMPLER STATE!!!",
  "CORRUPTING WELLNESS DATABASE... GOING BACK TO BASICS!!!"
];

const DemonWellnessGame = () => {
  const { setPersonality } = usePersonality();
  
  const initialState = {
    inventory: [],
    karma: 0,
    isReformed: false,
    cursedStreak: 0,
    message: "",
    showIntervention: false,
    gameEnded: false
  };

  const [inventory, setInventory] = useState(initialState.inventory);
  const [karma, setKarma] = useState(initialState.karma);
  const [isReformed, setIsReformed] = useState(initialState.isReformed);
  const [cursedStreak, setCursedStreak] = useState(initialState.cursedStreak);
  const [message, setMessage] = useState(initialState.message);
  const [showIntervention, setShowIntervention] = useState(initialState.showIntervention);
  const [isLoading, setIsLoading] = useState(false);
  const [gameEnded, setGameEnded] = useState(initialState.gameEnded);
  const [transitionMessage, setTransitionMessage] = useState("");

  const generateInterventionMessage = useCallback(async () => {
    setIsLoading(true);
    try {
      const prompt = "As a concerned wellness coach, generate a short, funny intervention message (max 100 characters) for a demon who's collecting too many cursed items.";
      
      const completion = await openai.chat.completions.create({
        messages: [{ role: "user", content: prompt }],
        model: "gpt-4-0125-preview",
        max_tokens: 50,
        temperature: 0.8,
      });

      setShowIntervention(false);
      setMessage(completion.choices[0].message.content);
    } catch (error) {
      console.error('Error generating intervention:', error);
      setMessage("Your wellness coach is concerned about your cursed item collection...");
      setShowIntervention(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (karma >= 1000) {
      setIsReformed(true);
    } else if (cursedStreak >= 5) {
      generateInterventionMessage();
      setCursedStreak(0);
    }
  }, [karma, cursedStreak, generateInterventionMessage]);

  const generateResponse = async (item) => {
    setIsLoading(true);
    try {
      let prompt;
      if (item.points < 0) {
        prompt = `As a demon who loves dark and cursed things, give a short, excited response (max 100 characters) about using a ${item.name}. Be dramatic and evil but funny.
        At a random moment in time you will decide that you are satisfied, have been uncorrupted and become unhinged and go into feral mode.
        That is when you have won the game.`;
      } else {
        prompt = `As a demon who hates wellness and healthy things, give a short, annoyed response (max 100 characters) about having to try ${item.name}. Be dramatic and grumpy but funny.
        At a random moment in time you will decide that you have found clarity and have been corrupted by the forces of good.
        That is when you have lost the game.`;
      }

      const completion = await openai.chat.completions.create({
        messages: [{ role: "user", content: prompt }],
        model: "gpt-4-0125-preview",
        max_tokens: 50,
        temperature: 0.8,
      });

      return completion.choices[0].message.content;
    } catch (error) {
      console.error('Error generating response:', error);
      return item.points < 0 
        ? "Ah yes, more cursed items! *evil laughter*" 
        : "Ugh... wellness. How dreadful.";
    } finally {
      setIsLoading(false);
    }
  };

  const handleItemUse = async (item) => {
    if (isReformed || isLoading || gameEnded) return;

    setInventory(prev => [...prev, item]);
    setKarma(prev => prev + item.points);
    
    if (item.points < 0) {
      setCursedStreak(prev => prev + 1);
    }

    const response = await generateResponse(item);
    setMessage(response);

    const responseLower = response.toLowerCase();
    if (responseLower.includes("unhinged") || responseLower.includes("feral") || responseLower.includes("lost control")) {
      setGameEnded(true);
      // Show random transition message
      const randomMessage = chaosTransitionMessages[Math.floor(Math.random() * chaosTransitionMessages.length)];
      setTransitionMessage(randomMessage);
      // Transition to Feral mode after showing message
      setTimeout(() => {
        setPersonality('feral');
      }, 3000);
    } else if (responseLower.includes("uncorrupted") || responseLower.includes("satisfied")) {
      setGameEnded(true);
      setIsReformed(true);
    }
  };

  const reset = () => {
    setInventory(initialState.inventory);
    setKarma(initialState.karma);
    setIsReformed(initialState.isReformed);
    setCursedStreak(initialState.cursedStreak);
    setMessage(initialState.message);
    setShowIntervention(initialState.showIntervention);
    setGameEnded(initialState.gameEnded);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-red-900 via-black to-red-900 flex flex-col">
      <div className="fixed top-4 left-4 right-4 flex justify-between items-center">
        <div className="text-red-500 font-bold">Karma: {karma}</div>
      </div>

      <div className="flex-1 flex flex-col items-center justify-center p-8">
        <div className="text-4xl mb-8">
          <div className={isReformed ? "animate-float" : "animate-pulse"}>
            {isReformed ? <AnnoyedDemonFace /> : <DemonFace />}
          </div>
        </div>

        {message && (
          <div className="text-red-400 mb-6 italic font-mono text-center max-w-md">
            {message}
          </div>
        )}

        {showIntervention && (
          <div className="bg-red-900/50 text-red-300 p-4 rounded-lg mb-6 font-mono text-center">
            {message}
          </div>
        )}

        <div className="flex flex-wrap justify-center gap-2 max-w-md mb-6">
          {inventory.map((item, i) => (
            <span 
              key={i}
              className="text-2xl animate-float"
              style={{ animationDelay: `${i * 0.1}s` }}
            >
              {item.emoji}
            </span>
          ))}
        </div>

        {!isReformed ? (
          <div className="flex flex-col gap-4">
            <div className="flex flex-wrap gap-2 justify-center">
              {wellnessItems.map((item, i) => (
                <button
                  key={i}
                  onClick={() => handleItemUse(item)}
                  className="px-4 py-2 rounded-full bg-pink-900/50 hover:bg-pink-800/50 text-pink-300 flex items-center gap-2 border border-pink-900"
                  disabled={isLoading}
                >
                  {item.emoji} Try {item.name}
                </button>
              ))}
            </div>
            <div className="flex flex-wrap gap-2 justify-center mt-4">
              {cursedItems.map((item, i) => (
                <button
                  key={i}
                  onClick={() => handleItemUse(item)}
                  className="px-4 py-2 rounded-full bg-red-900/50 hover:bg-red-800/50 text-red-300 flex items-center gap-2 border border-red-900"
                  disabled={isLoading}
                >
                  {item.emoji} Use {item.name}
                </button>
              ))}
            </div>
          </div>
        ) : (
          <div className="text-center">
            <div className="text-red-300 mb-4 font-mono">
              "Fine... I'll try this 'self-care' thing. But I'm keeping my horns!"
            </div>
            <button
              onClick={reset}
              className="px-6 py-3 rounded-full bg-red-900/50 hover:bg-red-800/50 text-red-300 flex items-center gap-2 border border-red-900 mx-auto"
            >
              <RotateCcw size={20} />
              Corrupt Another Demon
            </button>
          </div>
        )}
      </div>

      {isLoading && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center">
          <div className="text-red-500 animate-pulse">
            Channeling demonic responses...
          </div>
        </div>
      )}

      {transitionMessage && (
        <div className="fixed inset-0 bg-black/90 flex items-center justify-center z-50">
          <div className="text-green-500 text-2xl font-mono animate-glitch text-center p-8">
            {transitionMessage}
            <div className="mt-4 text-sm opacity-75">
              TRANSITIONING TO FERAL MODE...
            </div>
          </div>
        </div>
      )}

      <div className="bg-black/50 text-red-900 p-4 text-center text-xs font-mono">
        💀 Hellish Self-Care Tips & Demonic Wellness™
        <br/>
        "Even demons need a spa day... in the lake of fire" - Version 6.66.6
      </div>
    </div>
  );
};

export default DemonWellnessGame; 