import React from 'react';
import { usePersonality } from '../contexts/PersonalityContext';
import DemonWellnessGame from './DemonWellnessGame';
import GothAIGame from './GothAIGame';
import FeralAIGame from './FeralAIGame';
import PropTypes from 'prop-types';

const GameContainer = () => {
  const { personality } = usePersonality();

  return (
    <div className="relative">
      {personality === 'demon' && <DemonWellnessGame />}
      {personality === 'goth' && <GothAIGame />}
      {personality === 'feral' && <FeralAIGame />}
    </div>
  );
};

GameContainer.propTypes = {
  children: PropTypes.node
};

export default GameContainer; 