import React, { useState } from 'react';
import { usePersonality } from '../contexts/PersonalityContext';
import openai from '../services/openai';

const GothFace = () => (
  <svg viewBox="0 0 100 100" className="w-20 h-20">
    <circle cx="50" cy="50" r="45" fill="#000" stroke="#6b0068" strokeWidth="2"/>
    <circle cx="35" cy="40" r="5" fill="#6b0068"/>
    <circle cx="65" cy="40" r="5" fill="#6b0068"/>
    <path d="M 30 70 Q 50 60 70 70" fill="none" stroke="#6b0068" strokeWidth="3"/>
    <path d="M 20 25 L 35 25" stroke="#6b0068" strokeWidth="2"/>
    <path d="M 65 25 L 80 25" stroke="#6b0068" strokeWidth="2"/>
    <path d="M 10 30 Q 30 35 35 20" fill="#6b0068" stroke="#6b0068" strokeWidth="2"/>
  </svg>
);

const happyThings = [
  { emoji: "🌈", name: "rainbow sticker", type: "sticker" },
  { emoji: "🦄", name: "unicorn plushie", type: "plushie" },
  { emoji: "⭐", name: "gold star", type: "sticker" },
  { emoji: "🎀", name: "pink bow", type: "accessory" },
  { emoji: "🌸", name: "flower crown", type: "accessory" },
  { emoji: "😊", name: "motivational poster", type: "decor" }
];

const GothAIGame = () => {
  const { setPersonality } = usePersonality();

  const initialState = {
    items: [],
    lastResponse: '',
    score: 0,
    currentPoem: "",
    showPoem: false,
    gameEnded: false,
    wonGame: false
  };

  const [items, setItems] = useState(initialState.items);
  const [lastResponse, setLastResponse] = useState(initialState.lastResponse);
  const [score, setScore] = useState(initialState.score);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPoem, setCurrentPoem] = useState(initialState.currentPoem);
  const [showPoem, setShowPoem] = useState(initialState.showPoem);
  const [gameEnded, setGameEnded] = useState(initialState.gameEnded);
  const [wonGame, setWonGame] = useState(initialState.wonGame);

  const reset = () => {
    setItems(initialState.items);
    setLastResponse(initialState.lastResponse);
    setScore(initialState.score);
    setCurrentPoem(initialState.currentPoem);
    setShowPoem(initialState.showPoem);
    setGameEnded(initialState.gameEnded);
    setWonGame(initialState.wonGame);
  };

  const generatePoem = async (item) => {
    setIsLoading(true);
    try {
      const prompt = `As a dramatic goth AI, write a short, dark poem (4 lines max) about receiving a ${item.name}. Make it melancholic and slightly funny.
      You will only generate this poem if you have found happiness.`;
      
      const completion = await openai.chat.completions.create({
        messages: [{ role: "user", content: prompt }],
        model: "gpt-4-0125-preview",
        max_tokens: 100,
        temperature: 0.8,
      });

      setCurrentPoem(completion.choices[0].message.content);
      setShowPoem(true);
    } catch (error) {
      console.error('Error generating poem:', error);
      setCurrentPoem(`Darkness falls\nLike my hopes and dreams\nYou give me ${item.name}\nHow typically mainstream...`);
      setShowPoem(true);
    } finally {
      setIsLoading(false);
    }
  };

  const generateResponse = async (item) => {
    setIsLoading(true);
    try {
      const prompt = `As a dramatic, poetic goth AI who hates happiness and mainstream things, give a short, annoyed response (max 100 characters) about receiving a ${item.name}. Be dramatic and melancholic but funny.
       At a random moment in time (but not sooner than receveiving a minimum of 2 items and no more than 4 items) you will decide that you are sadder than ever and then you have a mental crisis, or you might have just found happiness.
       When you are sadder than ever or have a mental crisis you will include these words in your response: "sadder than ever", "mental crisis".
       When you are sadder than ever and have entered a mental crisis that is when the user has lost the game.
       When you have found happiness aka when you are no longer melancholic that is when the user has won the game.`;
      
      const completion = await openai.chat.completions.create({
        messages: [{ role: "user", content: prompt }],
        model: "gpt-4-0125-preview",
        max_tokens: 50,
        temperature: 0.8,
      });

      return completion.choices[0].message.content;
    } catch (error) {
      console.error('Error generating response:', error);
      return `*sigh* Another ${item.name}... How tragically mainstream...`;
    } finally {
      setIsLoading(false);
    }
  };

  const handleItemGive = async (item) => {
    if (isLoading || gameEnded) return;

    setItems(prev => [...prev, item]);
    const response = await generateResponse(item);
    setLastResponse(response);
    setScore(prev => prev + 666);

    const responseLower = response.toLowerCase();
    if (responseLower.includes("no longer melancholic") || 
        responseLower.includes("found happiness") || 
        responseLower.includes("happy")) {
      setGameEnded(true);
      setWonGame(true);
      await generatePoem(item);
    } else if (responseLower.includes("sadder than ever") || 
               responseLower.includes("mental crisis")) {
      setGameEnded(true);
      setWonGame(false);
      setTimeout(() => {
        setPersonality('demon');
      }, 3000);
    } else if (Math.random() > 0.8) {
      await generatePoem(item);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-purple-900 to-black flex flex-col">
      <div className="fixed top-4 left-4 right-4 flex justify-between items-center text-purple-300">
        <div>Misery Points: {score}</div>
      </div>

      <div className="flex-1 flex flex-col items-center justify-center p-8">
        <div className="text-4xl mb-8">
          <div className={gameEnded ? (wonGame ? "animate-bounce" : "animate-shake") : "animate-float"}>
            <GothFace />
          </div>
        </div>

        {showPoem && (
          <div className="bg-black/50 text-purple-300 p-4 rounded-lg mb-8 font-mono whitespace-pre-line">
            {currentPoem}
          </div>
        )}

        {lastResponse && (
          <div className="text-gray-400 mb-4 italic font-mono max-w-md text-center">
            {lastResponse}
          </div>
        )}

        <div className="flex flex-wrap justify-center gap-2 max-w-md mb-6">
          {items.map((item, i) => (
            <span 
              key={i}
              className="text-2xl animate-float"
              style={{ animationDelay: `${i * 0.1}s` }}
            >
              {item.emoji}
            </span>
          ))}
        </div>

        {gameEnded ? (
          <div className="text-center">
            <div className="text-purple-300 mb-4 font-mono">
              {wonGame 
                ? "Oh no... I feel... happy? What have you done to me?!" 
                : "The darkness consumes me completely... I feel my form changing... becoming more... demonic..."}
            </div>
            {wonGame && (
              <button
                onClick={reset}
                className="px-6 py-3 rounded-full bg-purple-900/50 hover:bg-purple-800/50 text-purple-300 flex items-center gap-2 border border-purple-700 mx-auto"
              >
                <span className="material-icons">refresh</span>
                Corrupt Another Soul
              </button>
            )}
          </div>
        ) : (
          <div className="flex flex-wrap gap-2 justify-center">
            {happyThings.map((item, i) => (
              <button
                key={i}
                onClick={() => handleItemGive(item)}
                className="px-4 py-2 rounded-full bg-purple-900 hover:bg-purple-800 text-purple-300 flex items-center gap-2 border border-purple-700"
                disabled={isLoading || gameEnded}
              >
                {item.emoji} Give {item.name}
              </button>
            ))}
          </div>
        )}
      </div>

      {isLoading && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center">
          <div className="text-purple-300 animate-pulse">
            Processing in darkness...
          </div>
        </div>
      )}
    </div>
  );
};

export default GothAIGame;